/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_Card from "../../2_components/card/C_Card";
import C_CardScroller from "../../2_components/cardScroller/C_CardScroller";
import gridScrollerOpts from "./gridScrollerOpts";

const S_GridScroller = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [loadState, setLoadState] = useState(false);
  let windowSize = window.innerWidth;

  useEffect(() => {
    windowSize < 800 || loadState == true ? setLoadState(true) : "";
  }, [windowSize]);

  useEffect(() => {
    let bannerVars = {
      ...appState,
      currentPath: "/ui/grid-scroller",
      outputName: "GridScroller",
      headerName: "Grid Scroller",
      tags: ["Landing Page Approved"],
      currentTheme: "t-pearl",
      description:
        "The Grid Scroller Section displays multiple cards that contain a photo, title, description, cta into a grid based layout on large view and scroller on small/medium view",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/cardScroller.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 Image Required", "Small, Medium, Large: Width: 310px, Height: 200px"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Tag", ["Standard Text, generated as a div (Optional)"]],
        [
          "Title",
          [
            "Standard Text, H3 tag with inherit global styling (Optional)",
            "Note: Please keep titles CONCISE. Recommend 140 characters or LESS",
          ],
        ],
        [
          "Tagline",
          [
            "Standard Text, generated as a span tag (Optional)",
            "Note: Recommend 20 characters or LESS. Max character limit is 40",
          ],
        ],
        ["Tagline Icon", ["Standard Text, Icon ID (Optional)"]],
        [
          "Copy",
          [
            "Standard Text, generated as a p tag (Optional)",
            "Note: Recommend 148 characters or LESS",
          ],
        ],
        [
          "CTA",
          [
            "Standard Text, recommend: refer to list of approved CTA text, otherwise max of two words. If there's no CTA, there is no link. If there's a CTA, then theres a link (Optional)",
          ],
        ],
        ["Link", ["A URL field to link the user for each card if CTA is present"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EVGuJ4mnvHNGtQt0xpuCquIB6SC92FTzOex3V3xcwC5P8g?e=MV7CEa",
        comments: [["Grid Scroller Image Size", ["310px x 200px"]]],
      },
    };
    setAppState(bannerVars);
    setContentOptions(gridScrollerOpts);
    setDimensions({
      ...dimensions,
      viewHeight: "346",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Grid Scroller";
  return (
    <section className={`s-cardScroller s-cardScroller--gridScroller ` + appState.currentTheme}>
      {contentOptions.numCards && contentOptions.numCards.selected == 1 ? (
        <div
          className="c-cardScroller c-cardScroller--fixed c-cardScroller--centerGrid"
          largegrid="true"
        >
          <button
            className="e-navigationArrow c-cardScroller__prevBtn"
            aria-label="Previous Slide"
            aria-hidden="true"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M20.235 7.298a1 1 0 00-1.41 0L12 13.875 5.255 7.344a1 1 0 10-1.4 1.42L12 16.574l8.215-7.866a1 1 0 00.02-1.41z"
                fillRule="nonzero"
              />
            </svg>
          </button>
          <div className="c-cardScroller__container">
            <div className="c-cardScroller__container__layout">
              <ul className="c-cardScroller__container__layout__items">
                <li className="c-cardScroller__container__layout__items__itemView">
                  <div className="c-cardScroller__container__layout__items__itemView__item">
                    {contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? (
                      <a
                        href="#link"
                        className={`c-card c-card--gridScroller c-card--lgRadius ${
                          contentOptions.imageoption &&
                          contentOptions.imageoption.selected == "Default"
                            ? ""
                            : "c-card--imageContained"
                        }`}
                      >
                        {contentOptions.tagsoption && contentOptions.tagsoption.checkbox ? (
                          <div className="e-tag c-card__tag ">
                            {contentOptions.card1[0] &&
                              contentOptions.card1[0].field[contentOptions.currentLang]}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="c-card__img">
                          <img
                            src="/images/fpo/general/310x200.png"
                            alt="Alternate Text"
                            data-dropdown="true"
                          />
                        </div>
                        <div className="c-card__details">
                          {contentOptions.titlesoption && contentOptions.titlesoption.checkbox ? (
                            <h3>
                              {contentOptions.card1[1] &&
                                contentOptions.card1[1].field[contentOptions.currentLang]}
                            </h3>
                          ) : (
                            ""
                          )}

                          {contentOptions.taglinesoption &&
                          contentOptions.taglinesoption.checkbox ? (
                            <div className="c-card__details__tagline">
                              {contentOptions.taglineicons &&
                              contentOptions.taglineicons.checkbox ? (
                                <svg className="c-card__details__tagline__icon">
                                  <use
                                    href={`/images/icons-map.svg${
                                      contentOptions.card1[2] && contentOptions.card1[2].field
                                    }`}
                                  />
                                </svg>
                              ) : (
                                ""
                              )}
                              <span>
                                {contentOptions.card1[3] &&
                                  contentOptions.card1[3].field[contentOptions.currentLang]}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {contentOptions.copiesoption && contentOptions.copiesoption.checkbox ? (
                            <p className="c-card__details__desc">
                              {contentOptions.card1[4] &&
                                contentOptions.card1[4].field[contentOptions.currentLang]}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? (
                          <div className="c-card__cta">
                            <div className="e-arrowCTA">
                              <span className="e-arrowCTA__copy">Lorem ipsum</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="32"
                                width="32"
                                viewBox="0 0 32 32"
                                aria-hidden="true"
                                className="e-arrowCTA__icon"
                              >
                                <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z" />
                              </svg>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </a>
                    ) : (
                      <div
                        className={`c-card c-card--gridScroller c-card--lgRadius ${
                          contentOptions.imageoption &&
                          contentOptions.imageoption.selected == "Default"
                            ? ""
                            : "c-card--imageContained"
                        }`}
                      >
                        {contentOptions.tagsoption && contentOptions.tagsoption.checkbox ? (
                          <div className="e-tag c-card__tag ">
                            {contentOptions.card1[0] &&
                              contentOptions.card1[0].field[contentOptions.currentLang]}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="c-card__img">
                          <img
                            src="/images/fpo/general/310x200.png"
                            alt="Alternate Text"
                            data-dropdown="true"
                          />
                        </div>
                        <div className="c-card__details">
                          {contentOptions.titlesoption && contentOptions.titlesoption.checkbox ? (
                            <h3>
                              {contentOptions.card1[1] &&
                                contentOptions.card1[1].field[contentOptions.currentLang]}
                            </h3>
                          ) : (
                            ""
                          )}

                          {contentOptions.taglinesoption &&
                          contentOptions.taglinesoption.checkbox ? (
                            <div className="c-card__details__tagline">
                              {contentOptions.taglineicons &&
                              contentOptions.taglineicons.checkbox ? (
                                <svg className="c-card__details__tagline__icon">
                                  <use
                                    href={`/images/icons-map.svg${
                                      contentOptions.card1[2] && contentOptions.card1[2].field
                                    }`}
                                  />
                                </svg>
                              ) : (
                                ""
                              )}
                              <span>
                                {contentOptions.card1[3] &&
                                  contentOptions.card1[3].field[contentOptions.currentLang]}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {contentOptions.copiesoption && contentOptions.copiesoption.checkbox ? (
                            <p className="c-card__details__desc">
                              {contentOptions.card1[4] &&
                                contentOptions.card1[4].field[contentOptions.currentLang]}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? (
                          <div className="c-card__cta">
                            <div className="e-arrowCTA">
                              <span className="e-arrowCTA__copy">Lorem ipsum</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="32"
                                width="32"
                                viewBox="0 0 32 32"
                                aria-hidden="true"
                                className="e-arrowCTA__icon"
                              >
                                <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z" />
                              </svg>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <button
            className="e-navigationArrow e-navigationArrow--right c-cardScroller__nextBtn"
            aria-label="Next Slide"
            aria-hidden="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M20.235 7.298a1 1 0 00-1.41 0L12 13.875 5.255 7.344a1 1 0 10-1.4 1.42L12 16.574l8.215-7.866a1 1 0 00.02-1.41z"
                fillRule="nonzero"
              />
            </svg>
          </button>
        </div>
      ) : (
        ""
      )}

      {contentOptions.numCards && contentOptions.numCards.selected == "More than 6" ? (
        <C_CardScroller classes="c-cardScroller--fixed" largegrid="true" loadmore={true}>
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link" : null}
            tag={
              contentOptions.tagsoption && contentOptions.tagsoption.checkbox
                ? contentOptions.card1[0] &&
                  contentOptions.card1[0].field[contentOptions.currentLang]
                : ""
            }
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card1[2] && contentOptions.card1[2].field
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card1[3] &&
                  contentOptions.card1[3].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card1[4] &&
                  contentOptions.card1[4].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
            tag={
              contentOptions.tagsoption && contentOptions.tagsoption.checkbox
                ? contentOptions.card2[0] &&
                  contentOptions.card2[0].field[contentOptions.currentLang]
                : ""
            }
            tagclass="e-tag--light"
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card2[2] && contentOptions.card2[2].field
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card2[3] &&
                  contentOptions.card2[3].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card2[4] &&
                  contentOptions.card2[4].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
            tag={
              contentOptions.tagsoption && contentOptions.tagsoption.checkbox
                ? contentOptions.card3[0] &&
                  contentOptions.card3[0].field[contentOptions.currentLang]
                : ""
            }
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card3[1] &&
                  contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card3[2] && contentOptions.card3[2].field
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card3[3] &&
                  contentOptions.card3[3].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card3[4] &&
                  contentOptions.card3[4].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card4[0] &&
                  contentOptions.card4[0].field[contentOptions.currentLang]
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card4[2] &&
                  contentOptions.card4[2].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card4[1] && contentOptions.card4[1].field
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card4[3] &&
                  contentOptions.card4[3].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card5[0] &&
                  contentOptions.card5[0].field[contentOptions.currentLang]
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card5[2] &&
                  contentOptions.card5[2].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card5[1] && contentOptions.card5[1].field
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card5[3] &&
                  contentOptions.card5[3].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card6[0] &&
                  contentOptions.card6[0].field[contentOptions.currentLang]
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card6[2] &&
                  contentOptions.card6[2].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card6[1] && contentOptions.card6[1].field
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card6[3] &&
                  contentOptions.card6[3].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
        </C_CardScroller>
      ) : contentOptions.numCards &&
        contentOptions.numCards.selected > 1 &&
        contentOptions.numCards &&
        contentOptions.numCards.selected <= 6 ? (
        <C_CardScroller
          // classes="c-cardScroller--fixed c-cardScroller--centerGrid"
          classes={`${
            contentOptions.numCards.selected === 2
              ? "c-cardScroller--fixed c-cardScroller--centerGrid"
              : "c-cardScroller--fixed"
          }`}
          largegrid="true"
        >
          <C_Card
            type={"gridScroller"}
            classes={
              contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                ? ""
                : "c-card--imageContained"
            }
            image="/images/fpo/general/310x200.png"
            url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link" : null}
            tag={
              contentOptions.tagsoption && contentOptions.tagsoption.checkbox
                ? contentOptions.card1[0] &&
                  contentOptions.card1[0].field[contentOptions.currentLang]
                : ""
            }
            title={
              contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                ? contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            taglineIcon={
              contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                ? contentOptions.card1[2] && contentOptions.card1[2].field
                : ""
            }
            radius={"large"}
            tagline={
              contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                ? contentOptions.card1[3] &&
                  contentOptions.card1[3].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                ? contentOptions.card1[4] &&
                  contentOptions.card1[4].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                ? contentOptions.ctaoption.field[contentOptions.currentLang]
                : null
            }
          />
          {(contentOptions.numCards && contentOptions.numCards.selected == 2) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 3) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 4) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 5) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 6) ? (
            <C_Card
              type={"gridScroller"}
              classes={
                contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                  ? ""
                  : "c-card--imageContained"
              }
              image="/images/fpo/general/310x200.png"
              url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
              tag={
                contentOptions.tagsoption && contentOptions.tagsoption.checkbox
                  ? contentOptions.card2[0] &&
                    contentOptions.card2[0].field[contentOptions.currentLang]
                  : ""
              }
              tagclass="e-tag--light"
              title={
                contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                  ? contentOptions.card2[1] &&
                    contentOptions.card2[1].field[contentOptions.currentLang]
                  : ""
              }
              taglineIcon={
                contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                  ? contentOptions.card2[2] && contentOptions.card2[2].field
                  : ""
              }
              radius={"large"}
              tagline={
                contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                  ? contentOptions.card2[3] &&
                    contentOptions.card2[3].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                  ? contentOptions.card2[4] &&
                    contentOptions.card2[4].field[contentOptions.currentLang]
                  : ""
              }
              cta={
                contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                  ? contentOptions.ctaoption.field[contentOptions.currentLang]
                  : null
              }
            />
          ) : (
            ""
          )}
          {(contentOptions.numCards && contentOptions.numCards.selected == 3) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 4) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 5) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 6) ? (
            <C_Card
              type={"gridScroller"}
              classes={
                contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                  ? ""
                  : "c-card--imageContained"
              }
              image="/images/fpo/general/310x200.png"
              url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
              tag={
                contentOptions.tagsoption && contentOptions.tagsoption.checkbox
                  ? contentOptions.card3[0] &&
                    contentOptions.card3[0].field[contentOptions.currentLang]
                  : ""
              }
              title={
                contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                  ? contentOptions.card3[1] &&
                    contentOptions.card3[1].field[contentOptions.currentLang]
                  : ""
              }
              taglineIcon={
                contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                  ? contentOptions.card3[2] && contentOptions.card3[2].field
                  : ""
              }
              radius={"large"}
              tagline={
                contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                  ? contentOptions.card3[3] &&
                    contentOptions.card3[3].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                  ? contentOptions.card3[4] &&
                    contentOptions.card3[4].field[contentOptions.currentLang]
                  : ""
              }
              cta={
                contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                  ? contentOptions.ctaoption.field[contentOptions.currentLang]
                  : null
              }
            />
          ) : (
            ""
          )}
          {(contentOptions.numCards && contentOptions.numCards.selected == 4) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 5) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 6) ? (
            <C_Card
              type={"gridScroller"}
              classes={
                contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                  ? ""
                  : "c-card--imageContained"
              }
              image="/images/fpo/general/310x200.png"
              url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
              title={
                contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                  ? contentOptions.card4[0] &&
                    contentOptions.card4[0].field[contentOptions.currentLang]
                  : ""
              }
              radius={"large"}
              tagline={
                contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                  ? contentOptions.card4[2] &&
                    contentOptions.card4[2].field[contentOptions.currentLang]
                  : ""
              }
              taglineIcon={
                contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                  ? contentOptions.card4[1] && contentOptions.card4[1].field
                  : ""
              }
              copy={
                contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                  ? contentOptions.card4[3] &&
                    contentOptions.card4[3].field[contentOptions.currentLang]
                  : ""
              }
              cta={
                contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                  ? contentOptions.ctaoption.field[contentOptions.currentLang]
                  : null
              }
            />
          ) : (
            ""
          )}
          {(contentOptions.numCards && contentOptions.numCards.selected == 5) ||
          (contentOptions.numCards && contentOptions.numCards.selected == 6) ? (
            <C_Card
              type={"gridScroller"}
              classes={
                contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                  ? ""
                  : "c-card--imageContained"
              }
              image="/images/fpo/general/310x200.png"
              url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
              title={
                contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                  ? contentOptions.card5[0] &&
                    contentOptions.card5[0].field[contentOptions.currentLang]
                  : ""
              }
              radius={"large"}
              tagline={
                contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                  ? contentOptions.card5[2] &&
                    contentOptions.card5[2].field[contentOptions.currentLang]
                  : ""
              }
              taglineIcon={
                contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                  ? contentOptions.card5[1] && contentOptions.card5[1].field
                  : ""
              }
              copy={
                contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                  ? contentOptions.card5[3] &&
                    contentOptions.card5[3].field[contentOptions.currentLang]
                  : ""
              }
              cta={
                contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                  ? contentOptions.ctaoption.field[contentOptions.currentLang]
                  : null
              }
            />
          ) : (
            ""
          )}
          {contentOptions.numCards && contentOptions.numCards.selected == 6 ? (
            <C_Card
              type={"gridScroller"}
              classes={
                contentOptions.imageoption && contentOptions.imageoption.selected == "Default"
                  ? ""
                  : "c-card--imageContained"
              }
              image="/images/fpo/general/310x200.png"
              url={contentOptions.ctaoption && contentOptions.ctaoption.checkbox ? "#link2" : null}
              title={
                contentOptions.titlesoption && contentOptions.titlesoption.checkbox
                  ? contentOptions.card6[0] &&
                    contentOptions.card6[0].field[contentOptions.currentLang]
                  : ""
              }
              radius={"large"}
              tagline={
                contentOptions.taglinesoption && contentOptions.taglinesoption.checkbox
                  ? contentOptions.card6[2] &&
                    contentOptions.card6[2].field[contentOptions.currentLang]
                  : ""
              }
              taglineIcon={
                contentOptions.taglineicons && contentOptions.taglineicons.checkbox
                  ? contentOptions.card6[1] && contentOptions.card6[1].field
                  : ""
              }
              copy={
                contentOptions.copiesoption && contentOptions.copiesoption.checkbox
                  ? contentOptions.card6[3] &&
                    contentOptions.card6[3].field[contentOptions.currentLang]
                  : ""
              }
              cta={
                contentOptions.ctaoption && contentOptions.ctaoption.checkbox
                  ? contentOptions.ctaoption.field[contentOptions.currentLang]
                  : null
              }
            />
          ) : (
            ""
          )}
        </C_CardScroller>
      ) : (
        ""
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "grid-scroller",
  component: S_GridScroller,
  navtxt: "Grid Scroller",
  htmlName: "GridScroller",
  categoryType: ["landing page"],
};
