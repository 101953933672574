/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";
import promoOpts from "./promoOpts";

const S_Promo = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const [phone, setPhone] = useState(null);
  const [tablet, setTablet] = useState(null);
  const [desktop, setDesktop] = useState(null);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let promoVars = {
      ...appState,
      currentPath: "/ui/promo",
      outputName: "Promo",
      headerName: "Promo",
      tags: ["Landing Page Approved"],
      description:
        "The Promo Section has two main areas for dynamic content, the background image and foreground copy. The copy appears over the image when in tablet or desktop view while the text will appear below the image in mobile view. One main feature about the promo section is that the height is dependent on the background image. Specs below should help determine the most optimal sizing but if the copy height exceeds the height of the image, then you may see some unwanted results.",
      related: null,
      currentTheme: null,
      htmlSaved: true,
      scripts: [],
      specs: [
        [
          "Images  (mobile, tablet, desktop)",
          [
            "*All 3 sizes required",
            "Small: 512 x 512",
            "Medium: 1024 x 450+",
            "Large: 2048 × 400+ *Recommended max height is 600px due to image scaling",
          ],
        ],
        ["Title", ["*Required. Character Limit: 100, recommend < 28 where it breaks into 2 lines"]],
        ["Paragraph", ["Character Limit: recommend < 140 which breaks into 4 lines. Max is 250"]],
        ["Primary Button", ["Primary default button is optional and is a solid color."]],
        ["Secondary Button", ["Secondary default button is optional and is transparent."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EUS3J0c2whFIuKXlpgI6wtMBlr5TduZng5FtnotytliD5w?e=fJvYvV",
        comments: [
          [
            "Promo Image Sizes",
            ["Small: 512px x 512px", "Medium: 1024px x 450px+", "Large: 2048px × 400px+"],
          ],
          ["Image Type", ["JPG"]],
          [
            "",
            [
              "*Recommended that images fade to black at the bottom for mobile devices",
              "*Safe zone above content in mobile view is always 20em (320px)",
            ],
          ],
          [
            "Title",
            [
              "*Required. The title can use either of the 6 heading tags <h1>-<h6>,  or it can be a span tag with the modifiers u-h1 to u-h6 depending on the desired styling. It is recommended to use a proper heading tag that is relevant to the page content over visual preference. Heading breaks into 2 lines at ~28 characters. ",
            ],
          ],
          [
            "Copy",
            [
              "Optional. The copy can technically be anything through the use of a WYSIWYG editor, this can include adding in buttons, links and features to launch a modal. There is standard width and wide copy area width. In the CMS there is a dropdown with the options 'standard' or 'large'. Recommend ~140 characters before breaking into 4 lines.",
            ],
          ],
          [
            "CTAs",
            [
              "Optional. Displays default sized buttons. Design is Primary and Secondary buttons with optional play icon (video opens in modal). CTA copy should be a short command, with the understanding that translations will be longer. If translations are very long, 'wide copy are' mode must be used so that the buttons don't stack. Character Limit: Goal is < 20 for English",
            ],
          ],
        ],
      },
    };
    setAppState(promoVars);
    setContentOptions(promoOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "387",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading Promo Section";
  return (
    <DropZone setPhone={setPhone} setTablet={setTablet} setDesktop={setDesktop} pictureTag={true}>
      <section
        className={`s-promo${
          contentOptions.txtColor && contentOptions.txtColor.checkbox ? " s-promo--txtWhite" : ""
        }${contentOptions.wideCopy && contentOptions.wideCopy.checkbox ? " s-promo--lgCopy" : ""}${
          contentOptions.txtMega && contentOptions.txtMega.checkbox ? " s-promo--txtMega" : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected === "Left"
            ? " s-promo--txtLeft"
            : contentOptions.alignment && contentOptions.alignment.selected === "Right"
              ? " s-promo--txtRight"
              : ""
        }`}
      >
        <picture className="e-picture s-promo__image" id="js-picture">
          <source
            srcSet={phone && phone.phone ? phone.phone : "/images/fpo/general/512x512.png"}
            media="(max-width:32em)"
          />
          <source
            srcSet={tablet && tablet.tablet ? tablet.tablet : "/images/fpo/general/1024x450.png"}
            media="(max-width:64em)"
          />

          <img
            src={desktop && desktop.desktop ? desktop.desktop : "/images/fpo/general/2048x600.png"}
            alt="Alternate Text"
          />
        </picture>
        <div className="s-promo__content">
          <article>
            {contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "SPAN" ? (
              <span className="s-promo__content__title u-h4">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </span>
            ) : contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "H1" ? (
              <h1 className="s-promo__content__title">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h1>
            ) : contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "H2" ? (
              <h2 className="s-promo__content__title">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h2>
            ) : contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "H3" ? (
              <h3 className="s-promo__content__title">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h3>
            ) : contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "H4" ? (
              <h4 className="s-promo__content__title">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h4>
            ) : contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "H5" ? (
              <h5 className="s-promo__content__title">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h5>
            ) : (
              <h6 className="s-promo__content__title">
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h6>
            )}
            {contentOptions.paragraph && contentOptions.paragraph.checkbox ? (
              <p className="s-promo__content__copy">
                {contentOptions.paragraph &&
                  contentOptions.paragraph.field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
            {(contentOptions.altBtn && contentOptions.altBtn.checkbox) || (contentOptions.btn && contentOptions.btn.checkbox) ? (
              <div className="s-promo__content__cta">
               {contentOptions.altBtn && contentOptions.altBtn.checkbox ? (
                  <a className="e-btn e-btn--alt2" target="_self" title="Button Title">
                    {contentOptions.altBtnPlay && contentOptions.altBtnPlay.checkbox ? (
                      <svg>
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#play-solid"
                        ></use>
                      </svg>
                    ) : (
                      ""
                    )}
                    <span>{contentOptions.altBtn.field[contentOptions.currentLang]}</span>
                  </a>
                ) : (
                  ""
                )}
                {contentOptions.btn && contentOptions.btn.checkbox ? (
                  <a className="e-btn" target="_self" title="Button Title">
                    {contentOptions.btnPlay && contentOptions.btnPlay.checkbox ? (
                      <svg>
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#play-solid"
                        ></use>
                      </svg>
                    ) : (
                      ""
                    )}
                    <span>
                      {contentOptions.btn && contentOptions.btn.field[contentOptions.currentLang]}
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </div>
            ) : ""}
          </article>
        </div>
      </section>
    </DropZone>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "promo",
  component: S_Promo,
  navtxt: "Promo",
  htmlName: "Promo",
  categoryType: ["landing page", "web"],
};
