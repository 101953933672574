/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

// import React, { useEffect, useContext, useState } from "react";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import productOpts from "./productOpts";
// import DropZone from "../../../0_hooks/dropzone";
let furyImg = "/images/fpo/section-products/ktc-features-memory-beast-ddr4.jpg";

const S_Product = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  // const [featureImage1, setFeatureImage1] = useState({ preview: "" });
  // const [featureImage2, setFeatureImage2] = useState({ preview: "" });
  // const [featureImage3, setFeatureImage3] = useState({ preview: "" });
  // const [featureImage4, setFeatureImage4] = useState({ preview: "" });

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/ui/product",
      outputName: "Product",
      headerName: "Product",
      tags: null,
      description:
        "The Product Section displays a description of the product, along with a list of features, images and CTA.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js", "product.section.js"],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: any as long as the ratio is 1:1",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        [
          "Feature Icons (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: any as long as the ratio is 1:1",
          ],
        ],
        ["Image Type", ["SVG"]],
        [
          "Video (mobile, tablet, desktop)",
          [
            "*1 video optional",
            "Small, Medium, Large: Current is Width: 416px, Height: 400px, any as long as the ratio is 1:1 and below current size",
          ],
        ],
        [
          "Heading",
          ["Supports <h1> to <h6>, but the heading defaults to a <h2> tag, Character limit is recommended to be < 50"],
        ],
        ["Subheading", ["Character limit is 120"]],
        ["Description", ["The copy appears as a paragraph beneath the heading. No Character limit."]],
        ["Features", ["String text, No Character limit."]],
        ["CTA", ["Fixed button and anchors to ecommerce section"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ETchSvMISVdBk0FvQHqPbx4BQjHQ39fmgDEEMv859UCFug?e=gu5vLD",
        comments: [
          ["Product Image Size", ["idk what the size is"]],
          ["Product Video Size", ["416px x 400px"]],
        ],
      },
    });
    setContentOptions(productOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "883",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading Product Section";
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <section className={`s-product ${appState.currentTheme}`}>
      <div class="c-heading">
        <div class="c-heading__block">
          <div class="c-heading__block__header">
          <>
               {contentOptions.headingtype && contentOptions.headingtype.selected == "H1" ? (
              <h1>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h1>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H2" ? (
              <h2>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h2>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H3" ? (
              <h3>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h3>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H4" ? (
              <h4>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h4>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H5" ? (
              <h5>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h5>
            ) : (
              <h6>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h6>
            )}
              </>                               
          </div>
        </div>
      </div>
      <div className="l-inner">
        <p className="intro">
          {contentOptions.description &&
            contentOptions.description.field[contentOptions.currentLang]}
        </p>
        <hr />
        <div className="features">
          {/* <div
            className="slider-wrapper"
            data-video="http://creative.us.kingston.corp/_hx/Resources/Section_Templates/images/fpo/section-ecommerce/alpha-rotate.mp4"
          >
            <video
              src="http://creative.us.kingston.corp/_hx/Resources/Section_Templates/images/fpo/section-ecommerce/alpha-rotate.mp4"
              muted="1"
              autoPlay="1"
              playsInline="1"
            ></video>
            <div
              className="feature-image-slides"
              data-lineart="../images/line_art/hx-features-headset-cloud-la.svg"
            >
              <div className="item">
                <DropZone setImage={setFeatureImage1}>
                  <img
                    data-title="Mav Special Edition"
                    alt="swatch"
                    data-color="../images/fpo/section-products/mav-swatch.png"
                    src={
                      featureImage1.preview
                        ? featureImage1.preview
                        : "https://styleguide.kingston.com/fpo/416/416"
                    }
                  />
                </DropZone>
              </div>
              <div className="item">
                <DropZone setImage={setFeatureImage2}>
                  <img
                    data-title="Black"
                    data-color="#000"
                    src={
                      featureImage2.preview
                        ? featureImage2.preview
                        : "https://styleguide.kingston.com/fpo/416/416"
                    }
                  />
                </DropZone>
              </div>
              <div className="item">
                <DropZone setImage={setFeatureImage3}>
                  <img
                    data-title="Red"
                    data-color="#c00"
                    src={
                      featureImage3.preview
                        ? featureImage3.preview
                        : "https://styleguide.kingston.com/fpo/416/416"
                    }
                  />
                </DropZone>
              </div>
              <div className="item">
                <DropZone setImage={setFeatureImage4}>
                  <img
                    data-title="White"
                    data-color="#ffffff"
                    src={
                      featureImage4.preview
                        ? featureImage4.preview
                        : "https://styleguide.kingston.com/fpo/416/416"
                    }
                  />
                </DropZone>
              </div>
            </div>
            <h5 className="feature-image-title">
              {contentOptions.name && contentOptions.name.field[contentOptions.currentLang]}
            </h5>
            <span className="replay">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref="../images/icons-map.svg#repeat"
                ></use>
              </svg>
            </span>
          </div> */}
          <div className="feature-image-slides slick-initialized slick-slider" data-lineart="">
            <div className="slick-list draggable">
              <div className="slick-track" style={{ opacity: 1, width: "468px" }}>
                <div
                  className="slick-slide slick-current slick-active"
                  data-slick-index="0"
                  aria-hidden="false"
                  style={{
                    width: "468px",
                    position: "relative",
                    left: "0px",
                    top: "0px",
                    zIndex: "999",
                    opacity: 1,
                  }}
                >
                  <div>
                    <div className="item" style={{ width: "100%", display: "inline-block" }}>
                      <img
                        alt="FURY Plug N Play memory"
                        data-color=""
                        data-title=""
                        src={furyImg}
                        title="FURY Plug N Play memory"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features-list-wrapper">
            <h2>Features</h2>
            <ul className="features-list u-list-unstyled">
            <li>
              <div className="svg-bullet">
                <svg>
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.feature1 && contentOptions.feature1[1] && contentOptions.feature1[1].field
                    }`}
                  ></use>
                </svg>
              </div>
              <span>
                {contentOptions.feature1 && contentOptions.feature1[0] && contentOptions.feature1[0].field[contentOptions.currentLang]}
              </span>
            </li>
            <li>
              <div className="svg-bullet">
                <svg>
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.feature2 && contentOptions.feature2[1] && contentOptions.feature2[1].field
                    }`}
                  ></use>
                </svg>
              </div>
              <span>
                {contentOptions.feature2 && contentOptions.feature2[0] && contentOptions.feature2[0].field[contentOptions.currentLang]}
              </span>
            </li>
            <li>
              <div className="svg-bullet">
                <svg>
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.feature3 && contentOptions.feature3[1] && contentOptions.feature3[1].field
                    }`}
                  ></use>
                </svg>
              </div>
              <span>
                {contentOptions.feature3 && contentOptions.feature3[0] && contentOptions.feature3[0].field[contentOptions.currentLang]}
              </span>
            </li>
            <li>
              <div className="svg-bullet">
                <svg>
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.feature4 && contentOptions.feature4[1] && contentOptions.feature4[1].field
                    }`}
                  ></use>
                </svg>
              </div>
              <span>
                {contentOptions.feature4 && contentOptions.feature4[0] && contentOptions.feature4[0].field[contentOptions.currentLang]}
              </span>
            </li>
            <li>
              <div className="svg-bullet">
                <svg>
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.feature5 && contentOptions.feature5[1] && contentOptions.feature5[1].field
                    }`}
                  ></use>
                </svg>
              </div>
              <span>
                {contentOptions.feature5 && contentOptions.feature5[0] && contentOptions.feature5[0].field[contentOptions.currentLang]}
              </span>
            </li>
            <li>
              <div className="svg-bullet">
                <svg>
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.feature6 && contentOptions.feature6[1] && contentOptions.feature6[1].field
                    }`}
                  ></use>
                </svg>
              </div>
              <span>
                {contentOptions.feature6 && contentOptions.feature6[0] && contentOptions.feature6[0].field[contentOptions.currentLang]}
              </span>
            </li>
            </ul>
          </div>
        </div>
        <div className="u-txt-center">
          <a className="e-btn" href="#buyLink" target="_self" title="Button Title">
            <span>
              {contentOptions.button && contentOptions.button.field[contentOptions.currentLang]}
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product",
  component: S_Product,
  navtxt: "Product",
  htmlName: "Product",
  categoryType: ["web"],
};
