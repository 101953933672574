import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import productLiteOpts from "./productLiteOpts";

const S_ProductLite = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let plVars = {
      ...appState,
      currentPath: "/ui/product-lite",
      outputName: "ProductLite",
      headerName: "Product Lite Section",
      tags: null,
      description: "The Product Lite Section provides a brief description with featured text.",
      related: null,
      currentTheme: "t-white",
      htmlSaved: true,
      scripts: [],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: Width: 30px, Height: 30px"],
        ],
        ["Image Type", ["SVG"]],
        [
          "Heading",
          ["Supports <h1> to <h6>, but the heading defaults to a <h2> tag, Character limit is recommended to be < 50"],
        ],
        ["Subheading", ["Character limit is 120"]],
        ["Description", ["The copy appears as a paragraph beneath the heading. No Character limit."]],
        [
          "Feature",
          ["The feature appears as a span. No Character limit but recommended max 2 lines."],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EQPeHcABD_REjNl2N9rQXr0BYopSZ39rQuTF4YPhaPMdWg?e=aPENQT",
        comments: null,
      },
    };
    setAppState(plVars);
    setContentOptions(productLiteOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "343" });
  }, []);

  if (!contentOptions || Object.keys(contentOptions).length < 1) return "...Loading Product Lite";
  return (
    <section className={`s-productLite ` + appState.currentTheme}>
       <div class="c-heading">
        <div class="c-heading__block">
          <div class="c-heading__block__header">
          <>
               {contentOptions.headingtype && contentOptions.headingtype.selected == "H1" ? (
              <h1>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h1>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H2" ? (
              <h2>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h2>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H3" ? (
              <h3>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h3>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H4" ? (
              <h4>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h4>
            ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H5" ? (
              <h5>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h5>
            ) : (
              <h6>
              {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.subheading.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h6>
            )}
              </>                       
          </div>
        </div>
      </div>
      <div className="l-inner">
        <p className="s-productLite__intro">
          {contentOptions.description && contentOptions.description.field[contentOptions.currentLang]}
        </p>
        <ul className="u-m0 u-p0 u-list-unstyled s-productLite__features">
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.feature1 && contentOptions.feature1[1] && contentOptions.feature1[1].field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature1 && contentOptions.feature1[0] && contentOptions.feature1[0].field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.feature2 && contentOptions.feature2[1] && contentOptions.feature2[1].field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature2 && contentOptions.feature2[0] && contentOptions.feature2[0].field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.feature3 && contentOptions.feature3[1] && contentOptions.feature3[1].field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature3 && contentOptions.feature3[0] && contentOptions.feature3[0].field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.feature4 && contentOptions.feature4[1] && contentOptions.feature4[1].field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature4 && contentOptions.feature4[0] && contentOptions.feature4[0].field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.feature5 && contentOptions.feature5[1] && contentOptions.feature5[1].field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature5 && contentOptions.feature5[0] && contentOptions.feature5[0].field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.feature6 && contentOptions.feature6[1] && contentOptions.feature6[1].field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature6 && contentOptions.feature6[0] && contentOptions.feature6[0].field[contentOptions.currentLang]}
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product-lite",
  component: S_ProductLite,
  navtxt: "Product Lite",
  htmlName: "ProductLite",
  categoryType: ["web"],
};
