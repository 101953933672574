export default {
    scenarios: {
        label: "CTA Scenarios",
        checkbox: null,
        field: ["Default", "Buy Now", "Partners", "Where to Buy", "String"],
        selected: "Default",
      },
      legend: {
        label: "Legend",
        checkbox: true,
        field: {
          Eng: "Lorem",
          Span: "Tamaño",
          ChS: "尺寸",
          Gr: "Größe",
          Ru: "Размер",
          Fr: "Taille",
          It: "Misurare",
          Pl: "Rozmiar",
          Port: "Tamanho",
          Viet: "Kích cỡ",
          Turk: "Boyut",
          Ukrn: "Розмір",
          Thai: "ขนาด",
          Kor: "크기",
          ChT: "尺寸",
          Jp: "サイズ",
        },
      },
      selection: {
        label: "Additional Selection",
        checkbox: false,
        field: {
          Eng: "Ipsum",
          Span: "Color",
          ChS: "颜色",
          Gr: "Farbe",
          Ru: "Цвет",
          Fr: "Couleur",
          It: "Colore",
          Pl: "Kolor",
          Port: "Cor",
          Viet: "Màu sắc",
          Turk: "Renk",
          Ukrn: "Колір",
          Thai: "สี",
          Kor: "색상",
          ChT: "顏色",
          Jp: "色",
        },
      },
      bullet1: {
        label: "Feature 1",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet2: {
        label: "Feature 2",
        checkbox: true,
        field: {
          Eng: "Consectetur adipiscing elit, sed do eiusmod.",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet3: {
        label: "Feature 3",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet4: {
        label: "Feature 4",
        checkbox: true,
        field: {
          Eng: "Consectetur adipiscing elit, sed do eiusmod.",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet5: {
        label: "Feature 5",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet6: {
        label: "Feature 6",
        checkbox: true,
        field: {
          Eng: "Consectetur: sed do eiusmod.",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      discountoption: {
        label: "Discount",
        checkbox: true,
        field: {
          Eng: "$1.00",
          Span: "$1.00",
          ChS: "$1.00",
          Gr: "$1.00",
          Ru: "$1.00",
          Fr: "$1.00",
          It: "$1.00",
          Pl: "$1.00",
          Port: "$1.00",
          Viet: "$1.00",
          Turk: "$1.00",
          Ukrn: "$1.00",
          Thai: "$1.00",
          Kor: "$1.00",
          ChT: "$1.00",
          Jp: "$1.00",
        },
      },
      saveoption: {
        label: "Save",
        checkbox: true,
        field: {
          Eng: "lorem 10%",
          Span: "lorem 10%",
          ChS: "lorem 10%",
          Gr: "lorem 10%",
          Ru: "lorem 10%",
          Fr: "lorem 10%",
          It: "lorem 10%",
          Pl: "lorem 10%",
          Port: "lorem 10%",
          Viet: "lorem 10%",
          Turk: "lorem 10%",
          Ukrn: "lorem 10%",
          Thai: "lorem 10%",
          Kor: "lorem 10%",
          ChT: "lorem 10%",
          Jp: "lorem 10%",
        },
      },
      vatoption: {
        label: "VAT",
        checkbox: false,
        field: {
          Eng: "VAT included",
          Span: "VAT incluido",
          ChS: "VAT 包括",
          Gr: "VAT inbegriffen",
          Ru: "VAT включено",
          Fr: "VAT inclus",
          It: "VAT incluso",
          Pl: "VAT dołączony",
          Port: "VAT incluído",
          Viet: "VAT bao gồm",
          Turk: "VAT dahil",
          Ukrn: "VAT включені",
          Thai: "VAT รวมอยู่ด้วย",
          Kor: "VAT 포함됨",
          ChT: "VAT 包括",
          Jp: "VAT 含まれています",
        },
      },
      copy: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      imagedisclaimer: {
        label: "Image Disclaimer",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      currentLang: "Eng",
  };
  